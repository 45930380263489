import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Quadrat from "./quadrat.png";
// import Q1_2018 from './1_2018.png';
// import Q1_2019 from './1_2019.png';
// import Q2_2019 from './2_2019.png';
// import Q1_2020 from './1_2020.png';
// import Q2_2020 from './2_2020.png';
// import Q3_2020 from './2_2020.png';
// import Q4_2020 from './2_2020.png';

const Home = () => {
    return (
        <Row
            style={{
                justifyContent: "center",
                backgroundColor: "#fff",
                minHeight: "100vh",
                paddingTop: 100,
            }}
        >
            <Container>
                <Row style={{ justifyContent: "center" }}>
                    <img
                        src={Quadrat}
                        style={{ height: "25%", width: "60%" }}
                        alt="Quadrat"
                    />
                </Row>

                <Row
                    style={{
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_1_2018_Quadrat">
                            <img
                                src="/Documents/1_2018.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_1_2019_Quadrat">
                            <img
                                src="/Documents/1_2019.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_2_2019_Quadrat">
                            <img
                                src="/Documents/2_2019.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                </Row>
                <Row
                    style={{
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_1_2020_Quadrat">
                            <img
                                src="/Documents/1_2020.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_2_2020_Quadrat">
                            <img
                                src="/Documents/2_2020.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_3_2020_Quadrat">
                            <img
                                src="/Documents/3_2020.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                </Row>
                <Row
                    style={{
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_4_2020_Quadrat">
                            <img
                                src="/Documents/4_2020.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_1_2021_Quadrat">
                            <img
                                src="/Documents/1_2021.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_2_2021_Quadrat">
                            <img
                                src="/Documents/2_2021.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                </Row>
                <Row
                    style={{
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_1_2023_Quadrat">
                            <img
                                src="/Documents/1_2023.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/revistei/Proza_nr_2_2023_Quadrat">
                            <img
                                src="/Documents/2_2023.png"
                                style={{
                                    height: 243,
                                    width: 168,
                                    margin: "20px 20px 20px 20px",
                                }}
                                alt="Quadrat"
                            />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default Home;

// <Row>
//     <Col>3</Col>
//     <Col>3</Col>
//     <Col>3</Col>
// </Row>
// <Row>
//     <Col>3</Col>
//     <Col>3</Col>
//     <Col>3</Col>
// </Row>

//             <Row style={{ justifyContent: 'space-around', alignItems: 'center' }}>
//     <img src={Q1_2018} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
//     <img src={Q1_2019} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
//     <img src={Q2_2019} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
// </Row>
// <Row style={{ justifyContent: 'space-around', alignItems: 'center' }}>
//     <img src={Q1_2020} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
//     <img src={Q2_2020} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
//     <img src={Q3_2020} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
// </Row>
// <Row style={{ justifyContent: 'space-around', alignItems: 'center' }}>
//     <img src={Q4_2020} style={{ height: 243, width: 168, margin: '20px 20px 20px 20px' }} alt="Quadrat" />
// </Row>
