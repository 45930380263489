import React from 'react';
import { useMedia } from "react-media";
import { Row } from 'react-bootstrap';

import Quadrat from './quadrat.png';

const Redactia = () => {
    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)",
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    return (
        <Row style={{ justifyContent: 'center', backgroundColor: '#fff', minHeight: '100vh', paddingTop: 100 }}>
            <center>
                <img src={Quadrat} style={{ height: matches.small ? "10%" : "25%", width: "60%" }} alt="Quadrat" /><br /><br />
                <h4>Editor:</h4>
                <h5>Fondul Culturii Scrise din Moldova</h5>

                <br /><h4>Redacția:</h4>
                Nicolae Spătaru, director<br />
                Grigore Chiper, redactor<br />
                <br /><h4>Consiliul de redacție:</h4>
                Vladimir Beșleagă<br />
                Iulian Ciocan<br />
                Lucreția Bârlădeanu<br />
                Florica Ciodaru-Courriol (Franța)<br />
                Alistair Ian Blyth (Marea Britanie)<br />
                Evgheny Stepanov (Rusia)<br />
                Volodymyr Danylenko (Ucraina)<br />
            </center>
        </Row>
    )
}

export default Redactia;