import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-media';
import { usePdf } from '@mikecousins/react-pdf';
import { Button, Row, Col, Form } from 'react-bootstrap';

const RevistaNumber = (props) => {
    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const { id } = useParams();

    const revistaId = id;
    const [page, setPage] = useState(3);
    const canvasRef = useRef(null);
    const fileName = "/Documents/" + revistaId + ".pdf";

    const { pdfDocument, pdfPage } = usePdf({
        file: fileName,
        page,
        canvasRef,
        scale: matches.small ? 1 : 1.4
    });

    useEffect(() => {
        setPage(3);
    }, [revistaId])

    const Select = (props) => {

        let option = [];

        for (var i = 1; i <= props.pages; i++) {
            option.push(i);
        }

        const onChangePage = (event) => {
            setPage(parseInt(event.target.value));
        }

        return (
            <Form.Control as="select" style={{ width: 100 }}
                value={page}
                onChange={onChangePage.bind(this)}
            >
                {option.map((elem) =>
                    <option key={elem} value={elem}>{elem}</option>
                )}
            </Form.Control>
        )

    }

    return (
        <Row style={{ justifyContent: 'center', backgroundColor: '#fff', minHeight: '100vh' }}>
            {!pdfDocument && <span>Se încarcă...</span>}
            <canvas ref={canvasRef} scale={matches.small ? 1 : 1.4} />
            {Boolean(pdfDocument && pdfDocument.numPages) && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Button variant="secondary" style={{ height: '2.4rem', marginLeft: 10, marginRight: 10 }}
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                        >
                            Anterior
                        </Button>
                        <Select style={{ width: 80 }}
                            page={page}
                            pages={pdfDocument.numPages}
                        />
                        <span style={{ marginLeft: 10, marginRight: 10, marginTop: 7, marginBottom: 10 }}> din {pdfDocument.numPages}</span>
                        <Button variant="secondary" style={{ height: '2.4rem', marginLeft: 10, marginRight: 10 }}
                            disabled={page === pdfDocument.numPages}
                            onClick={() => setPage(page + 1)}
                        >
                            Următor
                        </Button>
                    </div>

                </div>
            )}
            {/* <Pdf scale={matches.small ? 1 : 1.4} file={fileName} page={page}>
                {({ pdfDocument, pdfPage, canvas }) => (
                    <>
                        {canvas}
                        {Boolean(pdfDocument && pdfDocument.numPages) && (
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Button variant="secondary" style={{ height: '2.4rem', marginLeft: 10, marginRight: 10, marginBottom: 10 }}
                                    disabled={page === 1}
                                    onClick={() => setPage(page - 1)}
                                >
                                    Anterior
                                </Button>
                                <Select style={{ width: 80 }}
                                    page={page}
                                    pages={pdfDocument.numPages}
                                />
                                <span style={{ marginLeft: 10, marginRight: 10, marginTop: 7, marginBottom: 10 }}> din {pdfDocument.numPages}</span>
                                <Button variant="secondary" style={{ height: '2.4rem', marginLeft: 10, marginRight: 10 }}
                                    disabled={page === pdfDocument.numPages}
                                    onClick={() => setPage(page + 1)}
                                >
                                    Următor
                                </Button>
                            </Row>
                        )}
                    </>
                )}
            </Pdf> */}
        </Row>
    );
}

export default RevistaNumber;