import React from "react";
import { Provider } from "react-redux";
import { useMedia } from "react-media";
import { createStore } from "redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";

import Navbar from "./Components/navbar/navbar";

import reducer from "./redux/reducers";

import RevistaNumber from "./Components/RevistaNumber";
import Redactia from "./Components/Redactia";
import Home from "./Components/Home";
//import Revista from './components/Revista';

const store = createStore(reducer);

const App = () => {
  const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 599px)",
    medium: "(min-width: 600px) and (max-width: 1199px)",
    large: "(min-width: 1200px)",
  };
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  let navBArStyles = "";

  return (
    <Provider store={store}>
      <Router basename="">
        {/* <div style={{ display: 'flex' }}> */}
        <Container>
          <Row>
            <Col
              style={{
                maxWidth: matches.small ? "100%" : 300,
                backgroundColor: "var(--bg)",
                padding: 0,
                margin: 0,
              }}
            >
              <Navbar />
            </Col>
            {/* <div className='data-container'> */}
            <Col md>
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route
                  path="/revistei"
                  exact
                  element={Home}
                />
                <Route
                  path="/revistei/:id"
                  element={<RevistaNumber />}
                // render={({ match }) => {
                //   const { id } = match.params;
                //   return <RevistaNumber revistaId={id} />;
                // }}
                />
                <Route path="/redactia" element={<Redactia />} />
                <Route
                  render={() => (
                    <p>Pagina nu a fost gasita</p>
                  )}
                />
              </Routes>
            </Col>
            {/* </div> */}
          </Row>
        </Container>
        {/* </div> */}
      </Router>
    </Provider>
  );
};

export default App;
