import React, { useState, createRef } from 'react';
import { connect } from 'react-redux';
import './navbar.css';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-media';

import { ReactComponent as MenuButton } from './menu.svg';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faTrashAlt, faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
// import { faBars, faCog } from "@fortawesome/free-solid-svg-icons";
// import { Trans } from '@lingui/macro';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

//library.add(faBars, faCalendarAlt, faCog, faTrashAlt);

const MenuItem = ({ item, navSubItem, selectedItem, selectedSubMenu, setSelected }) => {
    //генерация пунктов меню

    const { id, name, link, icon, iconPrefix } = item;
    const rightIcon = '';//если субменю сделать значек для раскрывания

    // console.log();
    let newClassName = 'x-nav-item ';
    if ((selectedItem && selectedItem === id) || (selectedSubMenu === id)) {
        newClassName += 'x-item-active ';
    }

    if (navSubItem && !selectedSubMenu) {
        newClassName += 'x-nav-item-sub-item';
    }

    return (
        <li
            className={newClassName}
            onClick={() => { setSelected(item, navSubItem) }}
        >
            <Link className='x-link' to={link}>
                <div className='x-nav-item-link'>
                    {/* <div className='nav-icon-left-item'>{icon && <FontAwesomeIcon icon={[iconPrefix ? iconPrefix : 'fas', icon]} />}</div> */}
                    <div className='x-nav-icon-left-item'></div>
                    {name}
                    {/* <span>{rightIcon}</span> */}
                    <span>{rightIcon}</span>
                </div>
            </Link >

        </li>
    )
};

const Navbar = ({ menu }) => {
    const GLOBAL_MEDIA_QUERIES = {
        small: "(max-width: 599px)",
        medium: "(min-width: 600px) and (max-width: 1199px)",
        large: "(min-width: 1200px)"
    };
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const [selectedItem, setSelectedItem] = useState(1);
    const [inProp, setInProp] = useState(false);
    const [selectedSubMenu, setSelectedSubMenu] = useState(null);

    const [shomMenu, setShowMenu] = useState(matches.small ? false : true);

    const setSelected = ({ id, type }, navSubItem) => {
        setSelectedItem(id);
        if (type === 'sub-menu') {
            setSelectedSubMenu(id);
            setInProp(true)
        } else {
            if (!navSubItem) {
                setSelectedSubMenu(null);
            }
            if (matches.small)
                setShowMenu(false);
        }
    }


    return (
        <div className={matches.small ? 'x-navbar-small' : 'x-navbar'}>
            <div style={{ position: 'relative' }} className='x-nav-header'>
                {matches.small &&
                    <div style={{ position: 'absolute', top: 0, left: 0, padding: 10, paddingLeft: 24 }} onClick={() => setShowMenu(!shomMenu)}>
                        <MenuButton fill='white' width={40} height={40} />
                    </div>
                }
                <b style={{ color: '#ff5c1d' }}>Quadrat</b>.press
            </div>
            {shomMenu &&
                <ul className='x-navbar-nav'>
                    {menu.map((item) => {
                        const { subItems } = item;
                        return (
                            <div key={item.id}>
                                <MenuItem
                                    item={item}
                                    selectedItem={selectedItem}
                                    selectedSubMenu={selectedSubMenu}
                                    setSelected={setSelected}
                                />
                                <TransitionGroup
                                    component="ul"
                                >
                                    {subItems && selectedSubMenu === item.id &&
                                        subItems.map((subItem) => {
                                            // const nodeRef = createRef(null);
                                            return (
                                                <CSSTransition
                                                    key={subItem.id}
                                                    in={inProp}
                                                    timeout={300}
                                                    // ref={nodeRef}

                                                    classNames="x-main-menu"
                                                >
                                                    <MenuItem
                                                        key={subItem.id}
                                                        // ref={nodeRef}
                                                        item={subItem}
                                                        navSubItem
                                                        selectedItem={selectedItem}
                                                        setSelected={setSelected}
                                                    />
                                                </CSSTransition>
                                            )
                                        })
                                    }
                                </TransitionGroup>
                            </div>
                        )
                    })}
                </ul>
            }
        </div>
    );
};

// const NewNavItem = () => {

// }

const mapStateToProps = ({ menu }) => {
    return {
        menu
    }
}

const mapDispatchToProps = () => {
    return {
        // onIncrease: (id) => { console.log(`increase ${id}`) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);