const initialState = {
    loading: true,
    error: null,
    menu: [
        {
            id: 1,
            type: "item",
            name: "Acasă",
            link: "/",
            icon: "bars",
        },
        {
            id: 2,
            type: "sub-menu",
            name: "Arhiva revistei",
            link: "/", //'/revistei',
            // icon: 'calendar-alt',
            // iconPrefix: 'far',
            subItems: [
                {
                    id: 101,
                    type: "item",
                    name: "2018, NR.1",
                    link: "/revistei/Proza_nr_1_2018_Quadrat",
                },
                {
                    id: 102,
                    type: "item",
                    name: "2019, NR.1",
                    link: "/revistei/Proza_nr_1_2019_Quadrat",
                },
                {
                    id: 103,
                    type: "item",
                    name: "2019, NR.2",
                    link: "/revistei/Proza_nr_2_2019_Quadrat",
                },
                {
                    id: 104,
                    type: "item",
                    name: "2020, NR.1",
                    link: "/revistei/Proza_nr_1_2020_Quadrat",
                },
                {
                    id: 105,
                    type: "item",
                    name: "2020, NR.2",
                    link: "/revistei/Proza_nr_2_2020_Quadrat",
                },
                {
                    id: 106,
                    type: "item",
                    name: "2020, NR.3",
                    link: "/revistei/Proza_nr_3_2020_Quadrat",
                },
                {
                    id: 107,
                    type: "item",
                    name: "2020, NR.4",
                    link: "/revistei/Proza_nr_4_2020_Quadrat",
                },
                {
                    id: 108,
                    type: "item",
                    name: "2021, NR.1",
                    link: "/revistei/Proza_nr_1_2021_Quadrat",
                },
                {
                    id: 109,
                    type: "item",
                    name: "2021, NR.2",
                    link: "/revistei/Proza_nr_2_2021_Quadrat",
                },
                {
                    id: 110,
                    type: "item",
                    name: "2023, NR.1",
                    link: "/revistei/Proza_nr_1_2023_Quadrat",
                },
                {
                    id: 111,
                    type: "item",
                    name: "2023, NR.2",
                    link: "/revistei/Proza_nr_2_2023_Quadrat",
                },
            ],
        },
        {
            id: 3,
            type: "item",
            name: "Redactia",
            link: "/redactia",
        },
    ],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_MENU_REQUEST":
            return {
                ...state,
                // menu: [],
                loading: true,
                error: null,
            };

        default:
            return state;
    }
};

export default reducer;
